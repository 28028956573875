@import url('https://content.votenow.tv/app/projects/oscars/oscars.css');

html, body {
  width: 100%;
}

body {
  margin: 0;
  font-family: 'Futura PT', sans-serif;
  text-align: center;
  overflow-x: hidden;
  overflow-y: scroll 
}

.no-scroll {
  width: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
}

body.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}

* {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  width: auto;
  max-width: 100%;
  max-height: 100%;
  display: block;
}

button {
  border: none;
  border-radius: 0;
  cursor: pointer;
}

h1, h2, h3, p, figure, img, button {
  margin: 0;
  padding: 0;
  background-color: transparent;
}

h1, h2, h3 {
  font-weight: normal;
}

svg, path, circle {
  fill: none;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

section {
  position: relative;
  width: 100%;
}

iframe {
  display: block;
}

button span,
button svg {
  pointer-events: none;
}

select, fieldset {
  appearance: none;
}

fieldset {
  border: none
}

h1 > i,
h2 > i,
h3 > i,
h4 > i {
  font-style: normal;
  font-family: 'CeraPro-Light', sans-serif;
  font-size: 0.9em;
  transform: translateY(-5%);
  display: inline-block;
}

:focus-visible {
  outline: none;
}
.focus-visible {
  outline: 2px solid blue;
}

#telescope-app {
  min-height: 400px;
}
